export const getActions = (item) => {
    const actions = {
        actions: [
            {
                label: 'Editar',
                icon: 'edit',
                action: 'edit'
            }
        ],
        extra: []
    };

    if(item.active) {
        actions.actions.push({
            label: 'Desactivar',
            icon: 'user-slash',
            action: 'disable'
        })
    } else {
        actions.actions.push({
            label: 'Activar',
            icon: 'user',
            action: 'enable'
        })
    }

    return actions;
}