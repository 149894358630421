import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import React from "react";

export default function FolderList({
    list,
    onSelectedFolder
}) {

    useEffect(() => {
       
    }, []);
    
    return (
        <>
            { list?.map((documento, i) => {
                return (
                    <div 
                        key={'folder-' + i}
                        className="col-12 col-md-2 d-flex flex-column align-items-center folder me-3 mb-3" 
                        onClick={() => onSelectedFolder(documento)}>
                        <span>
                            <FontAwesomeIcon icon={faFolder} size="2x" color="#F8D775" />
                        </span>
                        <p className="mt-2">{documento.empleado.num_empleado}</p>
                        <small>{documento.empleado.nombre}</small>
                    </div>
                )
            })}
        </>
    );
}