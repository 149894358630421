import { combineReducers } from "redux";
import { searchReducer } from "./redux/search";
import { readOnlyDataReducer, readOnlyDataStatusReducer } from "./redux/read_only";
import { usersReducer, usersStatusReducer } from "./redux/users";
import { allUsersFullfilledReducer, departamentosFullfilledReducer, fiestasFullfilledReducer, rolesFullfilledReducer, tipoDocumentosFullfilledReducer, tipoUsersFullfilledReducer, tiposAusenciaFullfilledReducer } from "./redux/read_only_data";
import { ausenciasReducer, ausenciasStatusReducer } from "./redux/ausencias";
import { documentosReducer, documentosStatusReducer } from "./redux/documentos";
import { notificacionesReducer, notificacionesStatusReducer } from "./redux/notificaciones";
import { dashboardReducer, dashboardStatusReducer } from "./redux/dashboard";
import { loadingReducer } from "./redux/loading";

const reducer = combineReducers({
    loading: loadingReducer,
    // UTILITIES
    search: searchReducer,
    // READ ONLY DATA
    read_only: combineReducers({
        entities: readOnlyDataReducer,
        status: readOnlyDataStatusReducer,
    }),
    users: combineReducers({
        entities: usersReducer,
        status: usersStatusReducer,
    }),
    ausencias: combineReducers({
        entities: ausenciasReducer,
        status: ausenciasStatusReducer,
    }),
    documentos: combineReducers({
        entities: documentosReducer,
        status: documentosStatusReducer,
    }),
    notificaciones: combineReducers({
        entities: notificacionesReducer,
        status: notificacionesStatusReducer,
    }),
    dashboard: combineReducers({
        entity: dashboardReducer,
        status: dashboardStatusReducer
    }),
    roles: rolesFullfilledReducer,
    departamentos: departamentosFullfilledReducer,
    tipo_users: tipoUsersFullfilledReducer,
    tipo_ausencias: tiposAusenciaFullfilledReducer,
    fiestas: fiestasFullfilledReducer,
    all_users: allUsersFullfilledReducer,
    tipo_documentos: tipoDocumentosFullfilledReducer
})

const rootReducer = reducer;

export default rootReducer;