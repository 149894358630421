import axios from "axios";

const pageLoad = new Date().getTime();

export const isNewerVersion = async () => {
    return new Promise((resolve, reject) => {
        axios.head('/').then((res) => {

            let lastmod = res.headers['last-modified']
            
            if (lastmod){
                const lastModTime = new Date(lastmod).getTime()
                resolve(lastModTime > pageLoad)
            }else{
                resolve(false)
            }
    }).catch((error) => {
        reject(error)
    })})
};

export const deleteCache = async () => {

    try {

        if (window?.caches) {
          const { caches } = window;
          const cacheNames = await caches.keys();
          const cacheDeletionPromises = cacheNames.map((n) => caches.delete(n));
  
          await Promise.all(cacheDeletionPromises);
          
        }
      } catch (error) {
        console.log('An error occurred while deleting the cache.', true);
        console.log(error, true);
      }      
  }

export const reload = () => {
    window.location.reload(true);
}
