import { useState, useEffect } from "react";
import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TipoAusenciasTab from "./components/TipoAusenciasTab";
import UserTab from "./components/UsersTab";

export default function Ajustes() {
    const [tab, setTab] = useState('tipo-ausencias');

    useEffect(() => {
        
    }, []);

    const handleChangeTab = (e, tab) => setTab(tab);
  
    return (
        <div className="content ajustes">
            <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeTab} aria-label="Ajustes">
                    <Tab label="Tipo de ausencias" value="tipo-ausencias" />
                    <Tab label="Usuarios" value="usuarios" />
                </TabList>
                </Box>
                <TabPanel value="tipo-ausencias">
                    <TipoAusenciasTab />
                </TabPanel>
                <TabPanel value="usuarios">
                    <UserTab />
                </TabPanel>
            </TabContext>
        </div>
    )
}