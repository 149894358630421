import React from 'react';
import VerticalBarChart from '../../../shared/components/Charts/components/VerticalBar';


export default function UserMetrics({currentUser, view}) {
        return (
            <div className='w-100 d-flex flex-column align-items-center justify-content-between my-3'>
                <div className="w-100 d-flex flex-wrap align-items-center justify-content-center mt-5">
                    <div className="col-12 col-md-9">
                        <VerticalBarChart 
                            title={'Vacaciones'} 
                            dataset={currentUser.holidays_chart_data}
                        />
                    </div>
                </div>
            </div>
        )
}
