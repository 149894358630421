import { useEffect } from "react";
import React from "react";
import moment from 'moment';
import { faBullhorn, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function NotificacionItem({
  notificacion,
  onArchive
}) {
  
  useEffect(() => { }, []);

  const getTime = (fecha) => {
    const now = moment();
    const date = moment(fecha);

    const minutes = now.diff(date, 'minutes');
    const hours = now.diff(date, 'hours');
    const days = now.diff(date, 'days');

    if(minutes <= 60) {
      return "Hace " + minutes + (minutes > 1 ? " minutos" : ' minuto');
    } else if(hours < 24) {
      return "Hace " + hours + (hours > 1 ? " horas" : " hora");
    } else {
      return "Hace " + days + (days > 1 ? ' días' : ' día');
    }
  }
  
  return (
    <div key={'notificacion-' + notificacion.id} className="notificacion">
        <FontAwesomeIcon icon={faBullhorn} size="1x" className="action" />

        <div className="d-flex flex-column ms-3">
            <div className="flex-grow-1">
              <span className="fecha">{getTime(notificacion.fecha)}</span>
            </div>
            <p>{notificacion.mensaje}</p>
        </div>

        <span title="Archivar" className="close" onClick={() => onArchive(notificacion)}>
          <FontAwesomeIcon icon={faTimes} size="1x" className="action" />
        </span>
    </div>
  );
  
}