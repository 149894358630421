import API from './api';
//import bcrypt from 'bcrypt';

export const authenticate = async (credentials) => {
    return API.post('/auth/login', credentials).then(res => res.data);
}

export const refresh = async (credentials) => {
  return API.post('/auth/refresh', credentials, {withCredentials: true,}).then(res => res.data);
}
