import { useState } from "react";
import React from "react";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { Box } from "@mui/material";
import moment from "moment";

export default function CustomDatePicker({
    label,
    minDateValue,
    maxDateValue,
    disableFuture,
    disablePast,
    disabledDates,
    onChangeDate
}) {
    const [dateValue, setDateValue] = useState(null);

    const isWeekend = (date) => {
        const day = date.day();
      
        return day === 0 || day === 6;
    };

    return (
        <div className="form-group">
            <label>{label}</label>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                <DatePicker
                    shouldDisableDate={(day) => {
                        const currDay = moment(day);

                        if(isWeekend(currDay)) {
                            return true
                        } else {
                            const isIn = disabledDates?.find(date => {
                                const currDate = moment(date);
                                return currDate.isSame(currDay)
                            })

                            return isIn;
                        }
                    }}
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    label=""
                    value={dateValue}
                    onChange={(newValue) => {
                        setDateValue(newValue);
                        onChangeDate(newValue)
                    }}
                    mask={'__/__/____'}
                    inputFormat="dd/MM/yyyy"
                    minDate={minDateValue}
                    maxDate={maxDateValue}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box className="date-picker" sx={{ position: 'relative' }}>
                            <input className="form-control" ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                        </Box>
                    )}
                />
            </LocalizationProvider>
        </div>
    );
}