import { useEffect, useState } from "react";
import React from "react";

import { isNewerVersion, reload } from "../../../../../../services/config";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function Config() {

  const [openModal, setOpenModal] = useState(false);

  const checkVersions = async () => {
    let isMustReload = await isNewerVersion()

    if (isMustReload){
        setOpenModal(true)
    }
  }

  useEffect(() => {
    checkVersions()

    const interval = setInterval(() => {
        checkVersions()
    }, 1000*60*3);
    return () => clearInterval(interval);
  }, []);
  
  const handleConfirm = (isConfirm) => {
    if (isConfirm){
        reload()
    } else {
        setOpenModal(false)
    }
  }

  const handleCloseModal = () => setOpenModal(false);
  
  return (
    <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby="new-version-title"
        aria-describedby="new-version-description">
      <DialogTitle id="new-version-title">
          <div className="w-100 d-flex align-items-center justify-content-between">
            ¡Nueva versión disponible!

              <span title="cerrar" className="action" onClick={() => handleCloseModal()}>
                  <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
              </span>
          </div>
        
      </DialogTitle>
      <DialogContent>
          <div className="w-100 h-100">
              <p className="m-0">
                Si quieres usar la nueva versión ya, pulsa <b>SI</b>. <br /> 
                Si estás en medio de algún proceso, pulsa <b>NO</b>. <br />
                En 3 minutos volverá a avisarte.
              </p>

              <div className="w-100 d-flex align-items-center justify-content-center mt-3">
                <button type="button" className="btn btn-primary me-3" onClick={() => handleConfirm(true)}>SI</button>
                <button type="button" className="btn btn-danger" onClick={() => handleConfirm(false)}>NO</button>
              </div>
          </div>
      </DialogContent>
    </Dialog>
  )
}