export const getActions = () => {
    const actions = {
        actions: [
            {
                label: 'Editar',
                icon: 'edit',
                action: 'edit'
            },
            {
                label: 'Eliminar',
                icon: 'delete',
                action: 'delete'
            }
        ],
        extra: []
    };

    return actions;
}