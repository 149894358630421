import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAusencias } from "../../../../../../redux/ausencias";
import Loading from "../../../shared/components/Loading";
import { aprobarAusencia } from "../../../shared/services/ausencias";
import { toast } from "react-toastify";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import AusenciasTable from "../shared/components/AusenciasTable";

export default function PendientesList({
    onConfirmAction
}) {
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));
    const [currentAusenciaId, setCurrentAusenciaId] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const ausencias = useSelector(state => {
        return state.ausencias.entities
    });
    const ausenciasStatus = useSelector(state => {
        return state.ausencias.status
    });

    useEffect(() => {
        dispatch(fetchAusencias(false));
    }, []);

    const handleApproval = async(ausenciaId, approved) => {
        const response = await aprobarAusencia({ id: ausenciaId, approved: approved, approved_by: user.id });

        if(response && response.success) {
            toast.success(`Ausencia ${approved ? 'aprovada' : 'rechazada'} correctamente.`);
            setCurrentAusenciaId(null);
            dispatch(fetchAusencias(false));
            onConfirmAction();
        }
    }
    const handleOpenDenied = (ausenciaId) => {
        setCurrentAusenciaId(ausenciaId);
        setOpenConfirm(true);
    }

    return (
        <div className="w-100">
            { (ausenciasStatus.loading === 'pending' || ausenciasStatus.loading === 'idle')  &&  <Loading/> }

            { (ausenciasStatus.loading === 'succeed' || ausenciasStatus.loading === 'rejected') && 
                <div className="w-100">
                    <AusenciasTable 
                        ausencias={ausencias?.filter(ausencia => ausencia.approved === null)}
                        from={'pendientes'}
                        textNoItems={'No hay ausencias para aprobar.'}
                        onApprobal={handleApproval}
                        onDenied={handleOpenDenied}
                    />
                </div>
            }

            { openConfirm && 
                <ConfirmModal 
                    onConfirmAction={(confirm) => handleApproval(currentAusenciaId, confirm ? false : true)} 
                    title={'Rechazar solucitud de ausencia'} 
                    description={'Estás seguro de rechazar la solicitud?'}
                    state={openConfirm}>
                </ConfirmModal>
            }
        </div>
    );
}