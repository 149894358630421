import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { nuevoTipoAusencia, upodateTipoAusencia } from "../../../../../shared/services/tipo_ausencias";

export default function TipoAusenciaForm({
    tipoAusencia, 
    isEdit,
    onCancel, 
    onActionDone
}) {
    const { register, formState: { errors }, handleSubmit, reset, watch, setValue } = useForm({
        defaultValues: {
            id: 0,
            nombre: '',
            limite_dias: null,
            auto_approve: false,
            color: ''
        }
    });

    useEffect(() => {
        reset(tipoAusencia);
    }, [tipoAusencia]);

    const handleSubmitTipo = async(data, e) => {
        e.preventDefault();
        let response;
        
        if(isEdit) { response = await upodateTipoAusencia(data); }
        else {
            delete data.id;
            response = await nuevoTipoAusencia(data);
        }

        if(response && response.success) {
            toast.success(response.message);
            onActionDone();
        }
    }

    // const onChangeAutoApproved = (e) => {
    //     const value = e.target.checked;
    //     setValue('auto_approve', value);
    // }

    const handleCancel = () => onCancel();
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitTipo)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={isEdit ? tipoAusencia.id : ''} />
               
                <div className="w-100 d-flex align-items-center">
                    <div className="form-group">
                        <label htmlFor="img">Color</label>
                        <input 
                            type="color"
                            {...register("color")} 
                            defaultValue={isEdit ? tipoAusencia.color : null} />
                    </div>

                    <div className="form-group">
                        <label>Auto aprobado</label>

                        <div className="d-flex align-items-center">
                            <input 
                                type="checkbox"
                                {...register("auto_approve")}
                                name={`auto_approve`}
                                defaultChecked={isEdit ? tipoAusencia.auto_approve : false} />
                                
                            <label className="ms-2 mb-0">
                                {watch('auto_approve') ? 'Si' : 'No'}
                            </label>
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex flex-column flex-grow-1">
                    <div className="form-group flex-grow-1">
                        <label htmlFor="nombre">Nombre</label>
                        <input 
                            type="text" 
                            className="form-control"
                            {...register("nombre", { required: true })} 
                            defaultValue={isEdit ? tipoAusencia.nombre : ''} />
                        
                        <div className="form-invalid">
                            {errors.nombre?.type === 'required' && "El nombre es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group flex-grow-1">
                        <label htmlFor="limite_dias">Límite de días (opcional)</label>
                        <input 
                            type="number" 
                            step={1}
                            className="form-control"
                            {...register("limite_dias")} 
                            defaultValue={isEdit ? tipoAusencia.limite_dias : null} />
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end ms-3 mt-4">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                    <button type="button" className="btn btn-default align-self-end ms-3" onClick={handleCancel}>Cancelar</button>
                </div>
            </form>
        </div>
    )
}