import { useEffect } from "react";
import React from "react";
import Weekdays from "./components/Weekdays";
import Days from "./components/Days";
import moment from 'moment';
import 'moment/locale/es';
import './calendar.scss';

export default function Calendar({
    users,
    activeDate
}) {

    const weekdays = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Div', 'Sab'];
    const months = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 
                    'Mayo', 'Junio', 'Julio', 'Agosto', 
                    'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    useEffect(() => {
        moment.locale('es');
    }, [users, activeDate]);
 
    return (
        <div className="w-100 calendar mt-3">
            { (users?.length > 0) ?
                <div className="calendar-body">
                    { (activeDate) &&
                        <div className="w-100 table-responsive">
                            <table className="calendar-table">
                                <Weekdays 
                                    currDay={activeDate}  
                                    weekdays={weekdays}
                                    months={months}
                                />

                                <Days 
                                    users={users}
                                    currDay={activeDate}  
                                    weekdays={weekdays}
                                    months={months}
                                />
                            </table>
                        </div>
                    }
                </div>

                : <p>No hay usuarios.</p>
            }
        </div>
    );
}