import { useState } from "react";
import React from "react";
import moment from 'moment';
import CustomDatePicker from "../CustomDatePicker";
import { useSelector } from "react-redux";

export default function ExportForm({
    dateFilter,
    userFilter,
    isUserNullable,
    tipoAusenciaFilter,
    onSubmitExport
}) {
    const users = useSelector(state => {
        return state.users.entities
    });
    const tipoAusencias = useSelector(state => {
        return state.tipo_ausencias
    });

    const [startDateValue, setStartDateValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);
    const [selectedUser, setSelectedUser] = useState(-1);
    const [selectedTipoAusencia, setSelectedTipoAusencia] = useState(-1);

    const isExportDisabled = () => {
        let disabled = false;

        if(tipoAusenciaFilter)  disabled = (selectedTipoAusencia !== null) ? false : true;
        if(userFilter) disabled = (!isUserNullable && selectedUser !== null) ? false : true;
        if(dateFilter) disabled = (startDateValue !== null && endDateValue !== null) ? false : true ;

        return disabled;
    }

    const handleChangeUser = (e) => setSelectedUser(parseInt(e.target.value));
    const handleChangeTipoAusencia = (e) => setSelectedTipoAusencia(parseInt(e.target.value));

    const onSubmit = () => {
        let data = {};
        if(dateFilter) {
            data.startDate = startDateValue ? moment(startDateValue).format('YYYY-MM-DD') : null;
            data.endDate = endDateValue ? moment(endDateValue).format('YYYY-MM-DD') : null;
        }

        if(userFilter) data.user_id = selectedUser;
        if(tipoAusenciaFilter) data.tipo_ausencia_id = selectedTipoAusencia;

        onSubmitExport(data);
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-column">
                { (dateFilter) &&
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className="form-group me-0 me-md-3">
                            <CustomDatePicker 
                                label={"Fecha inicial"}
                                disableFuture={false}
                                minDateValue={null}
                                onChangeDate={setStartDateValue}
                            />
                        </div>
                        
                        <div className="form-group">
                            <CustomDatePicker 
                                label={"Fecha final"}
                                disableFuture={false}
                                minDateValue={startDateValue}
                                onChangeDate={setEndDateValue}
                            />
                        </div>
                    </div>
                }

                { (userFilter) &&
                    <div className="form-group">
                        <label>Empleado</label>
                        { (users && users.length > 0) &&
                            <select 
                                id="user_id"
                                defaultValue={-1}
                                onChange={handleChangeUser}>
                                {!isUserNullable ? <option value={-1}>Todos</option> : null}
                                {users.map(op => <option key={'user-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }
                    </div>
                }

                {(tipoAusenciaFilter) &&
                    <div className="form-group">
                        <label>Tipo de ausencia</label>
                        { (tipoAusencias && tipoAusencias.length > 0) &&
                            <select 
                                id="tipo_ausencia_id"
                                defaultValue={-1}
                                onChange={handleChangeTipoAusencia}>
                                <option value={-1}>Todas</option>
                                {tipoAusencias.map(op => <option key={'tipo-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }
                    </div>
                }
            </div>

            <div className="w-100 d-flex align-items-center justify-content-end mt-4">
                <button 
                    type="button" 
                    className="btn btn-primary" 
                    disabled={isExportDisabled()}
                    onClick={onSubmit}>Exportar</button>
            </div>
        </div>
    );
}