import { useEffect } from "react";
import React from "react";
import { useSelector } from "react-redux";

export default function Header({
    tipoDocumento,
    onChangeTipoDocumento,
}) {
    const tipoDocumentos = useSelector(state => {
        return state.tipo_documentos
    });

    useEffect(() => {
    }, []);

    return (
        <div className="w-100">
            <div className="w-100 d-flex align-items-center justify-content-between my-4">
                <div className="d-flex align-items-center">
                    <div className="me-3">
                        <label>Tipo de documento:</label>
                        <select 
                            id="tipo_documento_id"
                            name="tipo_documento_id"
                            defaultValue={tipoDocumento}
                            onChange={onChangeTipoDocumento}>
                            <option value={"-1"}>Todos</option>
                            {tipoDocumentos?.map(tipo => {
                                return tipo.id !== 2 && tipo.id !== 3 && <option key={'tipo-documento-' + tipo.id} value={tipo.id}>{tipo.nombre}</option>})}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
}