import { faDownload, faFile, faFileExcel, faFileImage, faFilePdf, faFileWord, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import React from "react";

export default function List({
    list,
    tipoDocumento,
    onDelete,
    onDownload
}) {
    const [documentosList, setDocumentosList] = useState([]);

    useEffect(() => {
        if(tipoDocumento && tipoDocumento !== -1) {
            filterDocuments();
        } else setDocumentosList(list);
    }, [list, tipoDocumento]);
    
    const filterDocuments = () => {
        const documentosAux = list.filter(doc => {
            return doc.tipo.id === tipoDocumento
        });

        setDocumentosList(documentosAux);
    }

    const getRows = (list) => {
        return list.map(doc => {
            const extension = doc.nombre.split('.')[1];

            return (
                <tr key={'doc-' + doc.id} className="doc">
                    <td className="text-center" width={"4%"}>
                        <span>
                            { (extension === 'png' || extension === 'jpg' || extension === 'jpeg') ?
                                    <FontAwesomeIcon icon={faFileImage} size="2x" color="#215732" />
                                : (extension === 'pdf') ?
                                    <FontAwesomeIcon icon={faFilePdf} size="2x" color="#215732" />
                                : (extension === 'docx') ?
                                    <FontAwesomeIcon icon={faFileWord} size="2x" color="#215732" />
                                : (extension === 'xlsx' || extension === 'xls') ?
                                    <FontAwesomeIcon icon={faFileExcel} size="2x" color="#215732" />
                                : <FontAwesomeIcon icon={faFile} size="2x" color="#215732" />
                            }
                        </span>
                    </td>
                    <td>
                        <p>{doc.nombre}</p>
                    </td>
                    <td width={"12%"}>{moment(doc.fecha).format('DD-MM-YYYY')}</td>
                    <td className="text-center" width={"8%"}>
                        <div className="w-100 d-flex align-items-center justify-content-center">
                            <span title="Rechazar" style={{ cursor: 'pointer' }}>
                                <FontAwesomeIcon icon={faDownload} size="1x" className="action" onClick={() => onDownload(doc)} color="#215732" />
                            </span> 

                            <span title="Rechazar" style={{ cursor: 'pointer' }}>
                                <FontAwesomeIcon icon={faTrash} size="1x" className="action" onClick={() => onDelete(doc.id)} color="#dc3545" />
                            </span> 
                        </div>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className="w-100 documentos-list-item">
           { documentosList?.length > 0 ?
            documentosList?.map((tipo, i) => {
                const tipoDoc = tipo.tipo ? tipo.tipo.id : tipo.tipoDocumento.id;

                return (
                    <div 
                        key={'tipo-' + i}
                        className="w-100 documentos-list-item">
                        <div className="w-100">
                            <div className="w-100 d-flex align-items-center empleado">
                                <small className="fw-bold text-info">
                                    { (tipoDoc === 2) ?
                                        'CONTRATOS'
                                        :
                                        (tipoDoc === 3) ?
                                        'NÓMINAS'
                                        :
                                        (tipoDoc === 4) ?
                                        'DOCUMENTOS MÉDICOS'
                                        :
                                        'OTROS'
                                    }
                                </small>
                            </div>

                            <div className="w-100 mb-3">
                                <div className="table-responsive"> 
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Archivo</th>
                                                <th>Fecha</th>
                                                <th className="text-center">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getRows(tipo.documentos)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )})
                :
                <p>No hay documentos.</p>
           }
        </div>
    );
}