import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import CustomDatePicker from "../../../../../shared/components/CustomDatePicker";
import moment from "moment";

export default function UserForm({user, isEdit, onSubmitUser}) {
    
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            id: '',
            nombre: '',
            password: '',
            telefono: '',
            email: '',
            birthday: '',
            start_on: '',
            dias_totales: '',
            rol_id: 1,
            dep_id: 1,
            tipo_user_id: 2
        }
    });
    const roles = useSelector(state => {
        const { roles } = state;
        return roles;
    });
    const departamentos = useSelector(state => {
        const { departamentos } = state;
        return departamentos;
    });
    const tipoUsers = useSelector(state => {
        const { tipo_users } = state;
        return tipo_users;
    });

    useEffect(() => {
        if(user) {
            reset({...user, tipo_user_id: user.tipo_id});
        } else reset();
    }, [user]);

    const handleChangeRol = (e) => { setValue('rol_id', parseInt(e.target.value)); }
    const handleChangeDep = (e) => { setValue('dep_id', parseInt(e.target.value)); }
    const handleChangeTipoUser = (e) => { setValue('tipo_user_id', parseInt(e.target.value)); }

    const onSubmit = (data, e) => {
        e.preventDefault();
        data.birthday = moment(data.birthday).format('YYYY-MM-DD');
        data.start_on = moment(data.start_on).format('YYYY-MM-DD');
        onSubmitUser(data);
    }

    return (
        <form className="w-100 form" onSubmit={handleSubmit(onSubmit)}>
            <input 
                type="hidden" 
                {...register("id")} 
                defaultValue={''} />

            <div className="w-100 d-flex flex-column">
                <div className="w-100 d-flex align-items-stretch">
                    <div className="form-group me-3">
                        <label htmlFor="nombre">Nombre</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            {...register("nombre", { required: true, maxLength: 50 })} 
                            defaultValue={''} />
                        
                        <div className="form-invalid">
                            {errors.nombre?.type === 'required' && "Nombre es obligatorio"}
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="nombre">DNI/NIE</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            {...register("dni", { required: true, maxLength: 20 })} 
                            defaultValue={''} />
                        
                        <div className="form-invalid">
                            {errors.nombre?.type === 'required' && "NIF es obligatorio"}
                        </div>
                    </div>
                </div>
                
                <div className="w-100 d-flex align-items-stretch">
                    <div className="form-group me-3">
                        <label htmlFor="email">Email</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            {...register("email", { required: true, maxLength: 255 })} 
                            defaultValue={isEdit ? user.email : ''} />

                        <div className="form-invalid">
                            {errors.username?.type === 'required' && "Email es obligatorio"}
                        </div>
                    </div>

                
                    <div className="form-group">
                        <label htmlFor="telefono">Telefono:</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            {...register("telefono")} 
                            defaultValue={''} />

                        <div className="form-invalid">
                            {errors.telefono?.type === 'required' && "Teléfono es obligatorio"}
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex align-items-stretch">
                    <div className="flex-grow-1">
                        <CustomDatePicker 
                            label={"Cumpleaños"}
                            disableFuture={true}
                            minDateValue={null}
                            onChangeDate={(value) => setValue('birthday', value)}
                        />

                        { (user && user?.birthday) ? 
                            <small>{moment(user.birthday).format('DD-MM-YYYY')}</small>
                            : ''
                        }
                    </div>

                    <div className="flex-grow-1 ms-3">
                        <CustomDatePicker 
                            label={"Primer día"}
                            disableFuture={true}
                            minDateValue={null}
                            onChangeDate={(value) => setValue('start_on', value)}
                        />

                        { (user && user?.start_on) ? 
                            <small>{moment(user.start_on).format('DD-MM-YYYY')}</small>
                            : ''
                        }
                    </div>
                </div>

                <div className="w-100 d-flex align-items-center">
                    <div className="form-group">
                        <label htmlFor="rol_id">Rol</label>
                        { (roles && roles.length > 0) &&
                            <select 
                                {...register("rol_id")}
                                defaultValue={isEdit ? user.rol_id : 1}
                                onChange={handleChangeRol}>
                                {roles.map(op => <option key={'rol-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }

                        <div className="form-invalid">
                            {errors.rol?.type === 'required' && "Seleccionar un rol es obligatorio"}
                        </div>
                    </div>

                    <div className={`form-group ${user ? 'mx-3' : 'ms-3'}`}>
                        <label htmlFor="dep_id">Departamento</label>
                        { (departamentos && departamentos.length > 0) &&
                            <select 
                                {...register("dep_id")}
                                defaultValue={1}
                                onChange={handleChangeDep}>
                                {departamentos.map(op => <option key={'departamento-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }

                        <div className="form-invalid">
                            {errors.rol?.type === 'required' && "Seleccionar un rol es obligatorio"}
                        </div>
                    </div>

                    {user && <div className="form-group">
                        <label htmlFor="tipo_user_id">Tipo de usuario</label>
                        { (tipoUsers && tipoUsers.length > 0) &&
                            <select 
                                {...register("tipo_user_id")}
                                onChange={handleChangeTipoUser}>
                                {tipoUsers.map(op => <option key={'tipoUser-' + op.id} value={op.id}>{op.nombre}</option>)}
                            </select>
                        }

                        <div className="form-invalid">
                            {errors.rol?.type === 'required' && "Seleccionar un rol es obligatorio"}
                        </div>
                    </div>}
                </div>

                <div className="form-group">
                    <label htmlFor="dias_totales">Dias totales de vacaciones</label>
                    <input 
                        type="number"
                        step={1} 
                        className="form-control" 
                        {...register("dias_totales", { required: true, maxLength: 50 })} 
                        defaultValue={''} />
                    
                    <div className="form-invalid">
                        {errors.dias_totales?.type === 'required' && "Nombre es obligatorio"}
                    </div>
                </div>

                { !user &&
                    <div className="form-group">
                        <label htmlFor="email">Password</label>
                        <input 
                            type="password" 
                            className="form-control" 
                            {...register("password", { required: true })} 
                            defaultValue={''} />

                        <div className="form-invalid">
                            {errors.username?.type === 'required' && "Password es obligatorio"}
                        </div>
                    </div>
                }
            </div>

            <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
        </form>
    )
}