import API from '../../../../../services/api';

export const getUserMetrics = async (userId, startDate, endDate, view) => {
    const params = '?user_id= ' + userId + '&startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    return await API.get('/dashboard/user-metrics' + params).then(res => res.data);
}

export const getGeneralMetrics = async (startDate, endDate, view) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    return await API.get('/dashboard/general-metrics' + params).then(res => res.data);
}