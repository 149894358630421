import API from '../../../../../services/api';

export const getNotificaciones = async (userId) => {
    return await API.get('/notificaciones/' + userId).then(res => res.data);
}

export const archivarNotificacion = async (notificacionId) => {
    return await API.put('/notificaciones/' + notificacionId, {}).then(res => res.data);
}

export const archivarAllNotificaciones = async (userId) => {
    return await API.put('/notificaciones/' + userId + '/archivar').then(res => res.data);
}