import { getAllUsers, getUsers } from "../views/Main/components/shared/services/users";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";

const asyncUsers = makeAsyncTypes('users');

const [setPending, setFullFilled, setError ] = asyncMac(asyncUsers);

export const usersFetchingReducer = makeFetchingReducer([
    'users/pending', 
    'users/fullfilled', 
    'users/rejected'
]);

const fullfilledReducer = makeSetReducer(['users/fullfilled']);

export const fetchUsers = (dep_id = null) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getUsers(dep_id)
        const data = await response.users;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const fetchUsersAjustes = (isAll) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getAllUsers(isAll)
        const data = await response.users;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const usersReducer = fullfilledReducer;
export const usersStatusReducer = usersFetchingReducer;