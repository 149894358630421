import { useState, useEffect, useRef } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAusenciasMensuales } from "../../../../../../redux/ausencias";
import Loading from "../../../shared/components/Loading";
import { getJustificanteAusencia, removeAusencia, uploadJustificante } from "../../../shared/services/ausencias";
import { toast } from "react-toastify";
import AusenciasTable from "../shared/components/AusenciasTable";
import InfoModal from "../../../shared/components/InfoModal";
import JustificanteForm from "./components/JustificanteForm";

export default function Resumen({
    start,
    end
}) {
    const dispatch = useDispatch();
    const firstRender = useRef(true);
    const [calendar, setCalendar] = useState(null);
    const [currentAusencia, setCurrentAusencia] = useState(null);
    const [isUploadJustificante, setIsUploadJustificante] = useState(false);

    const ausencias = useSelector(state => {
        return state.ausencias.entities
    });
    const ausenciasStatus = useSelector(state => {
        return state.ausencias.status
    });

    useEffect(() => {
        if (firstRender.current) {
            dispatch(fetchAusenciasMensuales(start, end));
            firstRender.current = false;
            return;
        }

        const cal = ausencias.filter(ausencia => ausencia.approved === null || ausencia.approved == true)
        .sort((a, b) =>{
            a = a.fecha_inicio.split('-').reverse().join('');
            b = b.fecha_inicio.split('-').reverse().join('');
            return a.localeCompare(b);
        })

        setCalendar(cal);
    }, [ausencias]);

    const handleDownloadJustificante = async(ausencia) => {
        const response = await getJustificanteAusencia(ausencia.id)

        if(response) {
            toast.success('Listado de compras descargado correctamente!')
          
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.setAttribute('download', ausencia.justificante);
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }

    const handleOpenUploadJustificante = (ausencia) => { setCurrentAusencia(ausencia); setIsUploadJustificante(true);}
    const handleCloseUploadJustificante = () => { setCurrentAusencia(null); setIsUploadJustificante(false);}
    const handleUploadJustificante = async(data) => {
        const response = await uploadJustificante(data, currentAusencia.id);

        if(response && response.success) {
            toast.success(response.message);
            setCurrentAusencia(null);
            setIsUploadJustificante(false);
            dispatch(fetchAusenciasMensuales(start, end));
        }
    }

    const handleDeleteAusencia = async(ausenciaId) => {
        const response = await removeAusencia(ausenciaId);

        if(response && response.success) {
            toast.success(response.message);
            dispatch(fetchAusenciasMensuales(start, end));
        }
    }

    return (
        <div className="w-100">
            { (ausenciasStatus.loading === 'pending' || ausenciasStatus.loading === 'idle')  &&  <Loading/> }

            { (ausenciasStatus.loading === 'succeed' || ausenciasStatus.loading === 'rejected') && 
                <div className="w-100">
                    <AusenciasTable 
                        ausencias={calendar}
                        from={'resumen'}
                        textNoItems={'No hay ausencias.'}
                        onDownloadJustificante={handleDownloadJustificante}
                        onUploadJustificante={handleOpenUploadJustificante}
                        onDelete={handleDeleteAusencia}
                    />

                    { (isUploadJustificante) &&
                        <InfoModal
                            title={"Subir justificante médico"}
                            content={
                                <JustificanteForm 
                                    onSubmitJustificante={handleUploadJustificante}
                                />
                            }
                            state={isUploadJustificante}
                            width={'sm'}
                            onClose={handleCloseUploadJustificante}
                        />
                    }
                </div>
            }
        </div>
    );
}