import { useEffect, useState } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Autocomplete, TextField } from "@mui/material";

export default function Header({
    user,
    tipoDocumento,
    selectedFolder,
    onOpenNew,
    onChangeUser,
    onChangeTipoDocumento,
}) {
    const users = useSelector(state => {
        return state.all_users
    });
    const tipoDocumentos = useSelector(state => {
        return state.tipo_documentos
    });
    const [options, setOptions] = useState([]);

    useEffect(() => {
        let newOps = [{id: -1, nombre: 'Todos'}];

        if(users) {
            newOps = [...newOps, ...users];
        }

        setOptions(newOps);
    }, []);

    return (
        <div className="w-100">
            <div className="w-100 d-flex align-items-center justify-content-between my-4">
                <div className="d-flex align-items-center">
                    { (!selectedFolder) ?
                    <div className="me-3">
                        <label>Empleado:</label>
                        <Autocomplete
                            disablePortal
                            id="owner_id"
                            options={options}
                            defaultValue={user}
                            getOptionLabel={(option) =>   `${option['nombre']}`}
                            onChange={onChangeUser}
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            }
                        />
                    </div>
                        :
                    <div className="me-3">
                        <label>Tipo de documento:</label>
                        <select 
                            id="tipo_documento_id"
                            name="tipo_documento_id"
                            defaultValue={tipoDocumento}
                            onChange={onChangeTipoDocumento}>
                            <option value={"-1"}>Todos</option>
                            {tipoDocumentos?.map(tipo => <option key={'tipo-documento-' + tipo.id} value={tipo.id}>{tipo.nombre}</option>)}
                        </select>
                    </div>
                    }
                </div>
                
                <div className="d-flex align-items-center">
                    <button className="btn btn-add" onClick={() => onOpenNew()}>
                        <span className="add">
                            <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                        </span>
                        Nuevo documento
                    </button>
                </div>
            </div>
        </div>
    );
}