
import { toast } from 'react-toastify';
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from './utils';
import { getReadOnlyData } from '../views/Main/components/shared/services/read_only';

const asyncReadOnly = makeAsyncTypes('read_only');

const [setPending, setFullFilled, setError ] = asyncMac(asyncReadOnly);

export const readOnlyFetchingReducer = makeFetchingReducer([
    'read_only/pending', 
    'read_only/fullfilled', 
    'read_only/rejected'
]);

const fullfilledReducer = makeSetReducer(['read_only/fullfilled']);

export const fetchReadOnlyData = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getReadOnlyData();
        const data = await response.read_only;
        dispatch(setFullFilled(data));
        dispatch({type: 'roles/fullfilled', payload: data.roles});
        dispatch({type: 'departamentos/fullfilled', payload: data.departamentos});
        dispatch({type: 'tipo_users/fullfilled', payload: data.tipo_users});
        dispatch({type: 'tipo_ausencias/fullfilled', payload: data.tipo_ausencias});
        dispatch({type: 'fiestas/fullfilled', payload: data.fiestas});
        dispatch({type: 'all_users/fullfilled', payload: data.users});
        dispatch({type: 'tipo_documentos/fullfilled', payload: data.tipo_documentos});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const readOnlyDataReducer = fullfilledReducer;
export const readOnlyDataStatusReducer = readOnlyFetchingReducer;