import { useEffect } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import './notificaciones.scss';
import NotificacionItem from "./components/NotificacionItem";
import { fetchNotificaciones } from "../../../../../../redux/notificaciones";
import { archivarAllNotificaciones, archivarNotificacion } from "../../services/notificaciones";

export default function Notificaciones({
  notificaciones,
  onArchived
}) {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  
  useEffect(() => {
    dispatch(fetchNotificaciones(userId));

    const interval = setInterval(() => {
      dispatch(fetchNotificaciones(userId));
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  const handleArchive = async(notificacion) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await archivarNotificacion(notificacion.id);

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      dispatch(fetchNotificaciones(userId));
      onArchived();
    }
  }

  const handleArchiveAll = async() => {
    dispatch({type: 'loading/set', payload: true});
    const response = await archivarAllNotificaciones(userId);

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      dispatch(fetchNotificaciones(userId));
      onArchived();
    }
  }

  return (
    <div className="w-100 d-flex flex-column">
      <div className="w-100 d-flex align-items-center justify-content-start mb-3">
        <button type="button" className="btn btn-info" onClick={() => handleArchiveAll()}>Archivar</button>
      </div>

      { notificaciones && notificaciones?.length > 0 ? notificaciones?.map(notificacion => {
        return (
          <NotificacionItem
            notificacion={notificacion}
            onArchive={handleArchive}
          />
        )
      })
      : <p className="text-center mt-3">No hay notificaciones.</p>
      }
    </div>
  );
  
}