import API from '../../../../../services/api';

export const getAllTipoAusencias = async () => {
    return await API.get('/tipo_ausencias').then(res => res.data);
}

export const nuevoTipoAusencia = async (tipoAusencia) => {
    return await API.post('/tipo_ausencias', tipoAusencia).then(res => res.data);
}

export const upodateTipoAusencia = async (tipoAusencia) => {
    return await API.put('/tipo_ausencias/' + tipoAusencia.id, tipoAusencia).then(res => res.data);
}

export const deleteTipoAusencia = async (tipoAusenciaId) => {
    return await API.delete('/tipo_ausencias/' + tipoAusenciaId).then(res => res.data);
}