import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";

export default function JustificanteForm({
    onSubmitJustificante
}) {
    const { 
        register, 
        formState: { errors }, 
        handleSubmit } = useForm({
            defaultValues: {
                justificante: null
            }
    });

 
    useEffect(() => {
    }, []);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        const ausencia = new FormData();

        if(data?.justificante && data?.justificante?.length > 0) { 
            Object.entries(data).forEach((value) => {
                if((value[0] === 'justificante') && value[1]?.length > 0) {
                    ausencia.append(value[0], value[1][0])
                } else { ausencia.append(value[0], value[1]) }
            });
        }

        onSubmitJustificante(ausencia);
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-100 d-flex flex-column">
                        <div className="form-group">
                            <label htmlFor="justificante">Justificante</label>
                            <input 
                                type="file" 
                                className="form-control" 
                                {...register("justificante", { maxLength: 255, required: true })} 
                                defaultValue={''} />

                            <div className="form-invalid mt-2">
                                {(errors.justificante) && "El justificante es obligatorio"}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-content-center align-self-end">
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}