import { getAusencias, getAusenciasMensuales } from "../views/Main/components/shared/services/ausencias";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";


const asyncAusencias = makeAsyncTypes('ausencias');

const [setPending, setFullFilled, setError ] = asyncMac(asyncAusencias);

export const ausenciasFetchingReducer = makeFetchingReducer([
    'ausencias/pending', 
    'ausencias/fullfilled', 
    'ausencias/rejected'
]);

const fullfilledReducer = makeSetReducer(['ausencias/fullfilled']);

export const fetchAusencias = (approved) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getAusencias(approved)
        const data = await response.ausencias;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const fetchAusenciasMensuales = (startDate, endDate) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getAusenciasMensuales(startDate, endDate)
        const data = await response.ausencias;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const ausenciasReducer = fullfilledReducer;
export const ausenciasStatusReducer = ausenciasFetchingReducer;