// LOAD ALL THE READ ONLY DATA
import { toast } from "react-toastify";
import { makeSetReducer } from "./utils";
import { getAllTipoAusencias } from "../views/Main/components/shared/services/tipo_ausencias";

export const rolesFullfilledReducer = makeSetReducer(['roles/fullfilled']);
export const tipoUsersFullfilledReducer = makeSetReducer(['tipo_users/fullfilled']);
export const departamentosFullfilledReducer = makeSetReducer(['departamentos/fullfilled']);
export const tiposAusenciaFullfilledReducer = makeSetReducer(['tipo_ausencias/fullfilled']);
export const fiestasFullfilledReducer = makeSetReducer(['fiestas/fullfilled']);
export const allUsersFullfilledReducer = makeSetReducer(['all_users/fullfilled']);
export const tipoDocumentosFullfilledReducer = makeSetReducer(['tipo_documentos/fullfilled']);

export const fetchTipoAusencias = () => async dispatch => {
    try {
        const response = await getAllTipoAusencias()
        const data = await response.data;
        dispatch({type: 'tipo_ausencias/fullfilled', payload: data });
    } catch (e) {
        toast.error(e.message)
    }
}