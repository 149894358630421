import { useState, useEffect } from "react";
import React from "react";
import moment from 'moment';
import 'moment/locale/es';
import { CustomTooltip } from "../../../../../../../shared/helpers/functionalities";
import { Badge } from "@mui/material";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCakeCandles } from "@fortawesome/free-solid-svg-icons";

export default function Days({
    currDay,
    users
}) {
    const fiestasNacionales = useSelector(state => {
      return state.fiestas
    });
    const [days, setDays] = useState([]);

    useEffect(() => {
      const today = moment();
      let currentDays = [];
      let startOfMonth =  moment(currDay).startOf('month');
      let daysInMonth = moment(startOfMonth).daysInMonth();

      for (let day = 0; day < daysInMonth; day++) {
        if (day !== 0) {
          startOfMonth = moment(startOfMonth).add(1, 'day')
        }
    
        let calendarDay = {
          currentMonth: (startOfMonth.month === currDay.month),
          date: moment(startOfMonth),
          month: startOfMonth.month() + 1,
          number: startOfMonth.date(),
          selected: (startOfMonth.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')),
          year: startOfMonth.year()
        }
    
        currentDays.push(calendarDay);
      }

      setDays(currentDays);
    }, [currDay, users]);

    const isHoliday = (date) => {
      return fiestasNacionales.find(d => {
          let curr; 

          if(d.infinite) {
              curr = moment(moment(date).format('YYYY') + '-' + (d.month < 10 ? ('0' + d.month) : d.month) + '-' + (d.day < 10 ? ('0' + d.day) : d.day));
          } else {
              curr = moment(d.year+ '-' + (d.month < 10 ? ('0' + d.month) : d.month) + '-' + (d.day < 10 ? ('0' + d.day) : d.day));
          }

          return curr.isSame(date);
      })
    }

    const isBirthDay = (user, day) => {
      const currentDay = moment(day.date);
      const birthday = user.birthday ? moment(user.birthday) : null;

      if(birthday) {
        return currentDay.month() === birthday.month() && currentDay.date() === birthday.date();
      } else return false;
    }

    const getAbsences = (user, day) => {
      const ausencias = user?.createdAbsences?.filter(timeOff => {
        const currentDay = moment(day.date)
        const start = moment(timeOff.fecha_inicio);
        const end = moment(timeOff.fecha_fin);
  
        if(currentDay.isBetween(start, end, 'days', '[]') && currentDay.day() !== 0 && currentDay.day() !== 6 && !isHoliday(currentDay)) {
          return true;
        } else return false;
      });

      if(ausencias && ausencias.length > 0 && ausencias.filter((ausencia) => (ausencia.approved === null) || (ausencia.approved === true))?.length > 0) {
        return ausencias.filter((ausencia) => (ausencia.approved === null) || (ausencia.approved === true))
        ?.map((ausencia, i) => {
          return (
            <span 
              key={'ausencia-symbol-' + ausencia.id }
              className={"circle " + (i !== ausencias.length-1 ? 'me-1' : '')} 
              style={{backgroundColor: ausencia.tipoAusencia.color }}
            ></span>
          )
        })
      } else return null;
    }
 
    return (
      <tbody>
        { users.map(user  => {
          const listDays = days.map((day) => {
            return (
              <td 
                key={'user-date-day-' + user.nombre + '-' + day.number + '-' + day.month}
                className={"text-center calendar-day" + (day.currentMonth ? " current" : "") + (day.selected ? " today" : "") + ((day.date.day() === 0 || day.date.day() === 6) ? " weekend" : "") + (isHoliday(day.date) ? " holiday" : "")}
              >
                <div className="d-flex h-100 flex-column align-items-center justify-content-between">
                  <p>{day.number}</p>
                  { (isBirthDay(user, day)) ?
                    <FontAwesomeIcon icon={faCakeCandles} size="1x" className="action" color="#ffd700" />
                    :
                    ''
                  }

                  <div className="w-100 d-flex align-items-center justify-content-center">
                    {getAbsences(user, day)}
                  </div>
                </div>
              </td>
            )
          })

          return (
            <tr key={'user-' + user.nombre + '-days'}>
              <td>
                
                <CustomTooltip
                  title={
                      <>
                        <div className="w-100">
                        
                        </div>
                      </>
                  }>
                  <Badge color="primary" badgeContent={(user.dias_restantes - user.dias_pendientes)}>
                    {user.nombre} &nbsp;&nbsp;&nbsp;&nbsp;
                  </Badge>
                </CustomTooltip>
              </td>
              {listDays}
            </tr>
          )
        }) }
      </tbody>
    )
}