
import API from '../../../../../services/api';

export const getUsers = async (dep_id = null) => {
    const departamentos = dep_id !== null ? '?dep_id=' + dep_id : '';
    return await API.get('/user' + departamentos).then(res => res.data);
}

export const getAllUsers = async (isAll) => {
    const all = isAll ? '' : '?active=true';
    return await API.get('/user/all' + all).then(res => res.data);
}

export const newUser = async (user) => {
    return await API.post('/user', user).then(res => res.data);
}

export const updateUser = async (user) => {
    return await API.put('/user/' + user.id, user).then(res => res.data);
}

export const enableDisableUser = async (userId) => {
    return await API.put('/user/' + userId + '/enable').then(res => res.data);
}

export const changePassword = async (userId, password) => {
    return await API.post(`/user/${userId}/password`, {password}).then(res => res.data)
  }