import { styled, Tooltip, tooltipClasses } from "@mui/material";

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
};

export const setErrorMessage = (error) => {
    if (error.response) {
        return error.response.data.message;
    } else if (error.request) {
        return 'Ups... algo ha ido mal';
    } else {
        return error.message;
    }
}

export const isBoolean = val => 'boolean' === typeof val;

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFF',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 450,
      fontSize: 12,
      border: '1px solid #4cb698',
    },
}));

export const isDuplicate = (item, arr) => { 
    return arr.some(el =>  item.nombre.toUpperCase() === el.nombre.toUpperCase() && levenshteinDistance(item.direccion.toUpperCase(), el.direccion.toUpperCase()) === 0);
}

export const levenshteinDistance = (str1 = '', str2 = '') => {
    const track = Array(str2.length + 1).fill(null).map(() =>
    Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i += 1) {
       track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
       track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j += 1) {
       for (let i = 1; i <= str1.length; i += 1) {
          const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
          track[j][i] = Math.min(
             track[j][i - 1] + 1, // deletion
             track[j - 1][i] + 1, // insertion
             track[j - 1][i - 1] + indicator, // substitution
          );
       }
    }
    return track[str2.length][str1.length];
 };

 export const getEstado = (transportistaId, codigo) => {
    if(transportistaId === 2) {
      if(codigo === '3') return 'text-green';
      else if(codigo === '91' || codigo === 'R6' || codigo === 'R1' || codigo === 'R2' || codigo === 'R3' || codigo === '1' || codigo === '11'|| codigo === '2') return 'text-info'
      else if (codigo === '4'|| codigo === 'R4' || codigo === 'R5') return 'text-warning'
      else if (codigo === '90'|| codigo === '1000' || codigo === '-1') return 'text-danger'
    } else {
      if(codigo === '3') return 'text-green';
      else if(codigo === '1' || codigo === '5' || codigo === '6' || codigo === '12' || codigo === '13' || codigo === '78'|| codigo === '80' || codigo === '77') return 'text-info'
      else if (codigo === '7'|| codigo === '8' || codigo === '9' || codigo === '10' || codigo === '11' || codigo === '14' || codigo === '85') return 'text-warning'
      else if (codigo === '15'|| codigo === '79' || codigo === '-1') return 'text-danger'
    }
  }